@import './theme.scss';

* {
  -webkit-backface-visibility:  hidden;
  -webkit-tap-highlight-color:  transparent;
  -webkit-border-radius: 0;
}

html {
  font-size: 62.5%;
  font-family: $font-family;
}

body {
  padding: 0;
  margin: 0;
  height: 100vh;
  display: flex;
  overflow: hidden;
  -moz-user-select:none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  #root {
    display: flex;
    flex: 1;
  }
}

p, h1, h2, h3 {
  margin: 0;
}

.btn {
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.18px;
  color: $text-color;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.adsbygoogle {
  display: none !important;
  border: 1px solid white;
  min-width: 120%;
  max-width: 25rem;
  height: 10rem;
  margin-bottom: 1rem;
}


@media (max-width: $breakpoint-medium) {
  .adsbygoogle {
    min-width: 90%;
    height: 8rem;
  }
}
