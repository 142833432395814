.modal {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .modal-content {
    background: linear-gradient($bg-primary-color 0%, $bg-secondary-color 100%);
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    animation: slideIntoView 1s;

    .btn-toggle-modal {
      height: 2rem;
      width: 2rem;
      position: absolute;
      top: 3.2rem;
      left: 3.2rem;
      background: transparent url("../images/btn-close.png") center no-repeat;
      background-size: contain;
      outline: none;
      border: none;
      cursor: pointer;
      z-index: 999;
      &.hover-show {
        opacity: 0.5;
        transition: 500ms all ease-in-out;
        -webkit-transition: 500ms all ease-in-out;
        -moz-transition: 500ms all ease-in-out;
        -ms-transition: 500ms all ease-in-out;
        -o-transition: 500ms all ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }

    .video-host {
      min-height: 14rem;
      min-width: 14rem;
      margin-bottom: 4.8rem;

      .video-container {
        height: 14rem;
        width: 14rem;
        border-radius: 50%;
        background: url("../images/btn-play.png") no-repeat center,
          linear-gradient(
              0deg,
              #c34fe4 0%,
              rgba(209, 140, 142, 0.9242) 75.52%,
              rgba(219, 184, 80, 0.87) 100%
            )
            center no-repeat;
        box-shadow: rgb(0 0 0 / 15%) 0 0.4rem 0.4rem;
        display: block;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }

        &.hidden {
          display: none;
        }

        &.disabled {
          pointer-events: none;
          cursor: default;
        }
      }
    }

    .level-controls {
      list-style: none;
      display: flex;
      height: 4.4rem;
      background-color: rgba($white-color, 0.05);
      border-radius: 2.1rem;
      justify-content: center;
      align-items: center;
      padding: 0.6rem 2.6rem;
      box-sizing: border-box;
      margin-bottom: 1.7rem;

      .control {
        color: $text-color;
        font-size: $font-size-l;
        line-height: 3.6rem;
        padding: 0 1rem;

        &.active {
          color: $level-control-active-color;
        }

        &:hover {
          cursor: pointer;
          color: $level-control-hover-color;
        }
      }
    }

    .btn-start {
      margin-bottom: 1.5rem;
      &:hover {
        opacity: 0.8;
      }
    }

    .btn-mode {
      margin-top: 2rem;
      margin-bottom: 5rem;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@keyframes slideIntoView {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
