.a-text
    font-family: $font-family
    color: $white-color
    font-size: 1.4rem
    font-weight: 400
    letter-spacing: 1.40px
    &.--textItalic
        font-style: italic
        letter-spacing: 1.50px
    &.--textSpaced
        font-weight: 400
        letter-spacing: 8px
    &.--medium
        font-size: 2rem
        font-weight: 400
        letter-spacing: 3.60px
    &.--mediumBold
        font-size: 2rem
        font-weight: 700
        letter-spacing: 2.50px
    &.--header
        font-size: 3.6rem
        letter-spacing: 5px
        font-weight: 600
    &.--headerSpaced
        font-size: 3.6rem
        font-weight: 600
        letter-spacing: 20px
    &.--center
        text-align: center
    &.--uppercase
        text-transform: uppercase
