@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
$font-family: 'Poppins', sans-serif;

// basic colors:
$white-color: #fff;
$black-color: #000;

// theme colors
$primary-color: #954AF3;
$text-color: rgba($white-color, 0.8);
$cookies-background: rgba(61,65,66,.85);
$bg-primary-color: #954AF3;
$bg-secondary-color: rgba(235, 82, 154, 0.85);
$level-control-active-color: rgb(219, 184, 80);
$level-control-hover-color: rgba(209, 140, 142, 0.9242);
$app-button-bg: #111111;
$app-button-focus-bg: #555555;
$app-button-focus-color: #333333;
$summary-item-border-color: rgba(233, 193, 76, 0.87);
$summary-item-bg-color: rgba(195, 79, 228, 0.1);
$summary-item-mobile-bg-color: #d38fa5;
$summary-item-active-bg-color: #d38fa5;

// font-sizes:
$font-size-xs: 1.2rem;
$font-size-s: 1.4rem;
$font-size-m: 1.6rem;
$font-size-xm: 1.8rem;
$font-size-md: 2rem;
$font-size-md-l: 3rem;
$font-size-md-xl: 3.2rem;
$font-size-l: 2.4rem;
$font-size-xl: 3.6rem;
$font-size-xxl: 4rem;
$font-size-xxxl: 6.9rem;
$font-size-xxxxl: 10rem;
$font-size-xxxxxl: 16rem;

// breakpoints:
$breakpoint-xsmall: 480px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1280px;
