.view {
  display: flex;
  flex: 1;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: linear-gradient($bg-primary-color 0%, $bg-secondary-color 100%);

  .main-content-wrapper {
    min-width: 50.8rem;
    max-width: 50.8rem;
    margin-top: 10rem;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white-color;
    flex-shrink: 0;
    flex-basis: auto;

    .subtitle {
      color: $text-color;
      font-weight: 600;
      font-size: $font-size-md-l;
      line-height: 4.5rem;
      letter-spacing: 0.75rem;
      text-align: center;
    }

    .app-image {
      box-shadow: rgb(0 0 0 / 15%) 0 0.4rem 0.4rem;
      background: linear-gradient(
        rgb(195, 79, 228) 0%,
        rgba(209, 140, 142, 0.925) 75.52%,
        rgba(233, 193, 76, 0.87) 100%
      );
      height: 35.4rem;
      width: 35.4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transform: scale(0.8);
      animation: slideIntoView 1s;

      .app-title {
        font-style: italic;
        font-size: $font-size-xxxl;
        line-height: 6.2rem;
        letter-spacing: 0.8rem;
        text-align: center;

        &:hover {
          cursor: pointer;
        }
      }

      .demo-btn {
        font-style: italic;
        text-transform: uppercase;
        font-size: $font-size-xm;
        line-height: 2.7rem;
        letter-spacing: 0.018rem;
        color: $text-color;
        position: absolute;
        bottom: 5rem;
      }
    }

    .video-host {
      min-height: 14rem;
      min-width: 14rem;
      margin: 4.8rem 0;

      .video-container {
        height: 14rem;
        width: 14rem;
        border-radius: 50%;
        background: url("../images/btn-play.png") no-repeat center,
          linear-gradient(
              0deg,
              #c34fe4 0%,
              rgba(209, 140, 142, 0.9242) 75.52%,
              rgba(219, 184, 80, 0.87) 100%
            )
            center no-repeat;
        box-shadow: rgb(0 0 0 / 15%) 0 0.4rem 0.4rem;
        display: block;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }

        &.hidden {
          display: none;
        }

        &.disabled {
          pointer-events: none;
          cursor: default;
        }
      }
    }

    .app-links {
      margin: 3.4rem 0;

      // ToDo replace with App store and Play market buttons
      a {
        padding: 1rem 1.6rem 1rem 4.5rem;
        margin: 0.5rem;
        font-size: $font-size-xs;
        line-height: 2rem;
        border-radius: 2.5rem;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        font-weight: 400;
        font-style: italic;
        text-decoration: none;
        display: inline-block;
        color: $white-color;
        background-color: $app-button-bg;
        position: relative;
        text-transform: lowercase;

        &:active:focus,
        &:focus {
          outline: 0;
        }

        &:focus,
        &:hover {
          color: $app-button-focus-color;
          text-decoration: none;
          outline: 0;
        }

        &:active {
          outline: 0;
          -webkit-box-shadow: inset 0 0.3rem 0.5rem rgba($black-color, 0.125);
          box-shadow: inset 0 0.3rem 0.5rem rgba($black-color, 0.125);
        }

        &:focus {
          color: $white-color;
          background-color: $app-button-focus-bg;
          border-color: $black-color;
        }

        &:active,
        &:hover {
          color: $white-color;
          background-color: $app-button-focus-bg;
          border-color: $black-color;
        }

        &:before {
          content: "";
          background-image: url(https://4.bp.blogspot.com/-52U3eP2JDM4/WSkIT1vbUxI/AAAAAAAArQA/iF1BeARv2To-2FGQU7V6UbNPivuv_lccACLcB/s30/nexus2cee_ic_launcher_play_store_new-1.png);
          background-size: cover;
          background-repeat: no-repeat;
          width: 3rem;
          height: 3rem;
          position: absolute;
          left: 1rem;
          top: 50%;
          margin-top: -1.5rem;
        }

        &.app-store {
          &:before {
            background-image: url("../images/apple-logo.png");
            background-size: contain;
            left: 1.2rem;
            top: 45%;
          }
        }

        &.disabled {
          pointer-events: none;
          cursor: default;
        }
      }
    }

    .description {
      color: $text-color;
      font-size: $font-size-xm;
      line-height: 2.7rem;
      letter-spacing: 0.178rem;
      text-align: center;

      &.full-description,
      &.mobile-description {
        padding: 0 1.5rem;
        font-size: $font-size-m;
      }
      &.mobile-description {
        margin-top: 1.5rem;
      }
    }

    .credentials {
      text-decoration: none;
      &.custom-credentials {
        margin-bottom: 2rem;
      }
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .view {
    .modal {
      .modal-content {
        .main-content-wrapper {
          justify-content: flex-start;
          margin-top: 1.5rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-small) {
  .view {
    .main-content-wrapper {
      min-width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      padding: 0 1rem 2rem;
    }
  }
}

@media (max-width: $breakpoint-xsmall) {
  .view {
    box-sizing: border-box;

    .main-content-wrapper {
      .app-links {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

@keyframes slideIntoView {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
  padding: 1rem 0;
}
