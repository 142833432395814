.o-mainPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white-color;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    @media (min-width: $breakpoint-small) {
      min-width: 50.8rem;
      max-width: 50.8rem;
    }
  }
  &__relativeContent {
    position: relative;
    z-index: 1;
  }
  &__upper {
    padding-bottom: 3rem;
  }
  &__bottom {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  &__centerCircleContainer {
    position: absolute;
    top: 0;
  }
  &__centerCircleWrapper {
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__centerCircle {
    box-shadow: rgb(0 0 0 / 15%) 0 0.4rem 0.4rem;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: linear-gradient(
      rgb(195, 79, 228) 0%,
      rgba(209, 140, 142, 0.925) 75.52%,
      rgba(233, 193, 76, 0.87) 100%
    );

    @media (orientation: portrait) {
      min-height: 125vh;
      width: auto;
      -webkit-animation: 14s linear 0s normal forwards shrinkCircleHeight,
        30s linear 14s infinite forwards rotate;
      animation: 14s linear 0s normal forwards shrinkCircleHeight,
        30s linear 14s infinite forwards rotate;
      &.--smaller {
        -webkit-animation: 14s linear 0s normal forwards
            shrinkCircleHeightSmaller,
          30s linear 14s infinite forwards rotate;
        animation: 14s linear 0s normal forwards shrinkCircleHeightSmaller,
          30s linear 14s infinite forwards rotate;
      }
    }
    @media (orientation: landscape) {
      min-width: 125vw;
      height: auto;
      -webkit-animation: 14s linear 0s normal forwards shrinkCircleWidth,
        30s linear 14s infinite forwards rotate;
      animation: 14s linear 0s normal forwards shrinkCircleWidth,
        30s linear 14s infinite forwards rotate;
      &.--smaller {
        animation: 14s linear 0s normal forwards shrinkCircleWidthSmaller,
          30s linear 14s infinite forwards rotate;
        -webkit-animation: 14s linear 0s normal forwards
            shrinkCircleWidthSmaller,
          30s linear 14s infinite forwards rotate;
      }
    }
  }

  &__centerCirclePolyfill {
    height: 32.4rem;
    width: 32.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh;
  }
  &__centerCircleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__circleTitle {
    font-style: italic;
    font-size: $font-size-xxxl;
    font-size: 7vw;
    // line-height: 4rem;
    line-height: 4vw;
    letter-spacing: 0.8rem;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }
  }
  &__circleButton {
    position: relative;
    top: 5rem;
  }
  &__appLinks {
    margin: 3.4rem 0;
    display: flex;

    justify-content: center;
    a {
      padding: 1rem 1.6rem 1rem 4.5rem;
      margin: 0.5rem;
      border-radius: 2.5rem;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      text-decoration: none;
      display: inline-block;
      color: $white-color;
      background-color: $app-button-bg;
      position: relative;
      text-transform: lowercase;
      &:active:focus,
      &:focus {
        outline: 0;
      }
      &:focus,
      &:hover {
        color: $app-button-focus-color;
        text-decoration: none;
        outline: 0;
      }
      &:active {
        outline: 0;
        box-shadow: inset 0 0.3rem 0.5rem rgba($black-color, 0.125);
      }
      &:active,
      &:hover,
      &:focus {
        color: $white-color;
        background-color: $app-button-focus-bg;
        border-color: $black-color;
      }
      &:before {
        content: "";
        background-image: url(https://4.bp.blogspot.com/-52U3eP2JDM4/WSkIT1vbUxI/AAAAAAAArQA/iF1BeARv2To-2FGQU7V6UbNPivuv_lccACLcB/s30/nexus2cee_ic_launcher_play_store_new-1.png);
        background-size: cover;
        background-repeat: no-repeat;
        width: 3rem;
        height: 3rem;
        position: absolute;
        left: 1rem;
        top: 50%;
        margin-top: -1.5rem;
      }
      &.app-store {
        &:before {
          background-image: url("../images/apple-logo.png");
          background-size: contain;
          left: 1.2rem;
          top: 45%;
        }
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
      }
    }
  }
  &__credentials {
    text-decoration: none;
    display: flex;
    justify-content: center;
  }
  .video-host {
    min-height: 14rem;
    min-width: 14rem;
    margin: 4.8rem 0;

    .video-container {
      height: 14rem;
      width: 14rem;
      border-radius: 50%;
      // background: url('../images/btn-play.png') no-repeat center, linear-gradient(0deg, #C34FE4 0%, rgba(209, 140, 142, 0.9242) 75.52%, rgba(219, 184, 80, 0.87) 100%) center no-repeat
      background: url("../images/btn-play.png") no-repeat center;
      display: block;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      &.hidden {
        display: none;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
      }
    }
  }
}

@-webkit-keyframes shrinkCircleWidth {
  0% {
    min-width: 125vw;
  }
  100% {
    // min-width: 32.4rem;
    min-width: 40vw;
  }
}

@keyframes shrinkCircleWidth {
  0% {
    min-width: 125vw;
  }
  100% {
    // min-width: 32.4rem;
    min-width: 40vw;
  }
}

@-webkit-keyframes shrinkCircleHeight {
  0% {
    min-height: 125vh;
  }
  100% {
    // min-height: 32.4rem;
    min-height: 40vh;
  }
}

@keyframes shrinkCircleHeight {
  0% {
    min-height: 125vh;
  }
  100% {
    // min-height: 32.4rem;
    min-height: 40vh;
  }
}

@-webkit-keyframes shrinkCircleWidthSmaller {
  0% {
    min-width: 125vw;
  }
  100% {
    // min-width: 18.4rem;
    min-width: 30vw;
  }
}

@keyframes shrinkCircleWidthSmaller {
  0% {
    min-width: 125vw;
  }
  100% {
    // min-width: 18.4rem;
    min-width: 30vw;
  }
}

@-webkit-keyframes shrinkCircleHeightSmaller {
  0% {
    min-height: 125vh;
  }
  100% {
    // min-height: 18.4rem;
    min-height: 30vh;
  }
}

@keyframes shrinkCircleHeightSmaller {
  0% {
    min-height: 125vh;
  }
  100% {
    // min-height: 18.4rem;
    min-height: 30vh;
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
