.slide-content {
  background: linear-gradient(-45deg, #954af3, #eb529a, #a76bf3, #f279b2);
  background-size: 400% 400%;
  animation: gradient 45s ease infinite;
  max-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;

  .slide-content-background {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .slide-content-circle {
      background: linear-gradient(
        #f0be2d,
        #de8e85,
        #dc7c9f,
        #da6eb3,
        #d861c6,
        #d656d5,
        #d446eb
      );
      border-radius: 50%;
      width: 90%;
      height: auto;
      aspect-ratio: 1 / 1;
      max-width: 1600px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px -12px #000;
      position: absolute;
      z-index: 0;
      animation: 14s infinite linear rotate;
      -webkit-animation: 14s infinite linear rotate;
      @media (min-width: $breakpoint-medium) {
        max-height: 80%;
        height: 1600px;
        aspect-ratio: 1 / 1 !important;
        width: auto;
      }
    }

    .slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;

      .pronunciation-slide-controls {
        position: absolute;
        top: 2rem;
        opacity: 0.2;
        transition: 500ms all ease-in-out;
        -webkit-transition: 500ms all ease-in-out;
        -moz-transition: 500ms all ease-in-out;
        -ms-transition: 500ms all ease-in-out;
        -o-transition: 500ms all ease-in-out;
        &:hover {
          opacity: 1;
        }

        .slide-indicator {
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          margin: 0;

          li {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            margin: 0.4rem 0.8rem;
            background: rgba($white-color, 0.3);

            &.active {
              background: rgba($white-color, 0.6);
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .pronunciation-version {
          color: rgba($white-color, 0.5);
          font-weight: 500;
          font-size: $font-size-m;
          line-height: 4rem;
          letter-spacing: -0.019rem;
          text-align: center;
          width: 100%;
        }
      }

      .pronunciation-details {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn-next {
          position: absolute;

          // left: calc(50%);
          // transform: translateX(-50%);
          bottom: -5rem;
        }
      }

      .pronunciation-translations {
        position: absolute;
        bottom: 1rem;
        font-size: $font-size-md;
        color: #fff;
      }

      .sub-heading {
        color: $white-color;
        font-weight: 600;
        font-size: 2vw;
        letter-spacing: 0.325rem;
        animation: slideIntoView 1s;
        text-align: center;
        position: absolute;
        top: -2vw;
      }

      .word {
        color: $white-color;
        font-weight: 600;
        font-size: 8vw;
        // line-height: 24rem;
        letter-spacing: 0.325rem;
        animation: slideIntoView 1s;
        text-align: center;
        margin-right: -0.325rem;

        &.pronunciation {
          letter-spacing: 2.281rem;
          margin-right: -2.281rem;
          word-break: break-all;
          font-size: 8vw;
        }
      }

      .no-results {
        color: $white-color;
        font-size: $font-size-md-xl;
        line-height: 4.8rem;
        text-align: center;
      }

      .share-subtitle {
        font-weight: 600;
        font-size: $font-size-xxl;
        line-height: 6rem;
        text-align: center;
        color: rgba($white-color, 0.6);
        letter-spacing: 3.253rem;
        position: absolute;
        top: 25%;
      }

      .summary-title {
        color: $white-color;
        font-size: $font-size-xl;
        line-height: 5.4rem;
        letter-spacing: 1.731rem;
        text-transform: uppercase;
        margin-bottom: 3.4rem;
      }

      .summary-navigation {
        list-style: none;
        padding: 0;

        li {
          background-color: rgba($white-color, 0.1);
          box-shadow: rgb(0 0 0 / 5%) 0 0.4rem 0.4rem;
          height: 6rem;
          width: 45rem;
          border-radius: 3rem;
          margin-bottom: 2.1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          font-weight: 700;
          font-size: $font-size-l;
          line-height: 3.6rem;
          color: $white-color;

          &:hover {
            cursor: pointer;
          }

          &.active {
            background-color: $summary-item-bg-color;
            box-shadow: rgb(0 0 0 / 5%) 0 0.4rem 0.4rem;
            animation: slideIntoView 1s;
          }
        }
      }

      .btn-more-words {
        font-size: $font-size-md;
        line-height: 3rem;
        letter-spacing: 0.231rem;
        margin-bottom: 3.8rem;
      }

      .app-details {
        color: rgba($white-color, 0.5);
        font-weight: 600;
        font-size: $font-size-s;
        line-height: 2.1rem;
        letter-spacing: 0.637rem;
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .slide-content {
    flex: unset;
    height: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: $breakpoint-small) {
  .slide-content {
    height: 100%;

    .slide-content-background {
      background-size: contain;

      .slide {
        .word {
          line-height: 10rem;

          &.pronunciation {
            letter-spacing: 1.7rem;
            margin-right: -1.7rem;
          }
        }

        .pronunciation-details {
          .btn-next {
            font-size: $font-size-md;
            // left: calc(50% - 2.5rem);
          }
          .sub-heading {
            top: -5rem;
            font-size: $font-size-md;
            // font-size: 4vw;
          }
        }

        .summary-title {
          text-align: center;
          font-size: $font-size-l;
        }

        .summary-navigation {
          width: 80%;

          li {
            width: 100%;
          }
        }

        .app-details {
          text-align: center;
          margin-bottom: 1rem;
        }

        .share-subtitle {
          letter-spacing: 1rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-xsmall) {
  .slide-content {
    .slide-content-background {
      .slide {
        .word {
          font-size: 12vw;
          line-height: 7rem;
          margin-bottom: 1rem;

          &.pronunciation {
            font-size: 8vw;
            letter-spacing: 1.2rem;
            margin-right: -1.2rem;
          }
        }
      }
    }
  }
}

@keyframes slideIntoView {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
