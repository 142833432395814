.cookie-box {
  width: 100%;
  background: $cookies-background;
  z-index: 1000000;
  padding: 2.5rem 10vw;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;

  .cookie-content {
    color: $white-color;
    font-size: $font-size-s;

    .cookie-box-title {
      font-weight: 500;
      font-size: $font-size-l;
      margin-bottom: 0.5rem;
    }

    .cookie-box-content {
      a {
        color: $primary-color;
        text-decoration: none;
      }
    }
  }

  .cookie-actions {
    display: flex;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 4rem;
      margin-top: 3rem;
      color: $white-color;
      border: 0.1rem solid $white-color;
      background: transparent;
      text-transform: uppercase;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      opacity: 0.5;
      padding: 0.5rem 1rem;
      border-radius: 0.4rem;

      &:hover {
        background: rgba($white-color, 0.2);
      }

      &.btn-accept {
        margin-left: 1rem;
        color: $primary-color;
        border: 0.1rem solid $primary-color;
        opacity: 1;
      }
    }
  }
}
