.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba($white-color, 0.75);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &.local {
    position: absolute;
    z-index: 98;
  }

  &.is-hidden {
    display: none;
  }

  &.is-visible {
    display: flex;
  }

  .loader {
    border: 0.5rem solid $summary-item-border-color;
    border-top: 0.5rem solid $primary-color;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
